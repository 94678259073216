import React, { useEffect, useState, useCallback } from "react";
import { Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../components/Loader";
import {
  useAddBidUserMutation,
  useAutoMatchMutation,
  useGetAddedUsersQuery,
  useGetSellersQuery,
  useLazyGetAddedUsersQuery,
  usePaymentSheetMutation,
  useUpdateSellersGraphNotificationsMutation,
} from "../../store/api";
import "./assignuser.scss";
import { Elements } from "@stripe/react-stripe-js";
import toast from "react-hot-toast";
import CustomButton from "../../components/custombutton/CustomButton";

import { useLocation, useNavigate } from "react-router-dom";
import CustomizeModal from "../../components/custommodel/CustomizeModal";

import RouteNames from "../../routes/RouteNames";
import { loadStripe } from "@stripe/stripe-js";
import { StripePayment } from "../../components/custommodel/StripePayment";
import { IoMdDownload } from "react-icons/io";
import { MdAutoGraph } from "react-icons/md";
import Modal from "react-modal";
import { trackEvent } from "../../utils/amplitude";
import { Events } from "../../utils/events";
// import { StripePayment } from "../../components/customtextfield/StripePayment";

// import { useElements, useStripe } from "@stripe/react-stripe-js";

function AssignUser() {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [isUserAvailable, setIsUserAvailable] = useState(true);
  const [addedUsers, setAddedUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [showCustomCard, setShowCustomCard] = useState(false);
  const [
    UpdateSellers,
    {
      isSuccess: isSuccessUpdateSellers,
      isError: isErrorUpdateSellers,
      isLoading: isLoadingUpdateSellers,
    },
  ] = useUpdateSellersGraphNotificationsMutation();

  const stripePromise = loadStripe(
    "pk_test_51NZyGNHp2vpsqcNCm4hUf09orJabuIC4jR2ofD5QdOvpL4YigxUi3Qr2pZdc61eWB50yWJfjA5UxQwy61Ey4El4p00bVPreNtp"
  );
  const token = useSelector((state) => state.auth?.user?.tokens?.access?.token);
  const userId = useSelector((state) => state.auth?.user?.user?.id);

  const [clientSecret, setClientSecret] = useState(null);
  const [paymentDoc, setPaymentDoc] = useState(null);
  const openUserModal = () => {
    setShowCustomCard(true);
  };

  const fetchPaymentSheetParams = async (user) => {
    try {
      setPaymentLoading(true);
      const response = await fetch(
        `https://api.liberdat.io/v1/payment/payment-sheet/${user.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const { paymentIntent, paymentDoc } = await response.json();
      console.log("paymentDoc", paymentIntent);
      setPaymentLoading(false);
      setClientSecret(paymentIntent);
      setPaymentDoc(paymentDoc);

      console.log("paymentIntent", paymentIntent);
      openPaymentModal();
    } catch (error) {
      console.error("Error fetching payment sheet params:", error);
    }
  };

  const selectedUserIdd = selectedUserId?.id;
  const [
    autoMatch,
    {
      data: dataAuto,
      isSuccess: AutoisSuccess,
      isError: AutoisError,
      error: Autoerror,
      isLoading: isLoadingAuto,
    },
  ] = useAutoMatchMutation();
  // console.log("selectedUserId:::", selectedUserId)

  const location = useLocation();
  // const stripe = useStripe();
  // const elements = useElements();
  const { bid } = location?.state || {};

  const [isAddButtonEnabled, setIsAddButtonEnabled] = useState(true);
  const userstatus = useSelector((state) => state?.auth?.user?.user);

  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [
    getAddedUsers,
    {
      data: addedUsersData,
      isError: iserr,
      isSuccess: suc,
      isLoading: load,
      refetch: refetchAddedUsers,
    },
  ] = useLazyGetAddedUsersQuery();

  const [paymentSheet, { data, isSuccess: issuc, isError: iserrr }] =
    usePaymentSheetMutation();

  // console.log('addedUsersData', addedUsersData[0].status);
  // const StatusOfSeller = addedUsersData.status;
  // console.log("Status", StatusOfSeller)
  // const { refetch } = useGetAddedUsersQuery();
  const [
    addBidUser,
    {
      isError: assignError,
      isSuccess: assignSuccess,
      error,
      data: manualUserData,
    },
  ] = useAddBidUserMutation();
  useEffect(() => {
    if (isSuccessUpdateSellers) {
      trackEvent(Events.USER_REQUEST_FOR_UPDATE);
      toast.success("Notification sent to all Accepted Sharers");
    }
  }, [isSuccessUpdateSellers]);
  useEffect(() => {
    if (AutoisSuccess) {
      getAddedUsers(bid?._id ? bid?._id : bid?.id);
      trackEvent(Events.USER_CLICKED_AUTOMATCH, dataAuto);
      toast.success("The Data request has been sent to potential Data sharers");
    } else if (AutoisError) {
      toast.error(`No User Found!`);
      trackEvent(Events.USER_CLICKED_AUTOMATCH_ERROR, Autoerror?.data?.message);
      console.log("AUTOMATCH ERROR", Autoerror);
      getAddedUsers(bid?._id ? bid?._id : bid?.id);
    }
  }, [AutoisSuccess, AutoisError, Autoerror, dataAuto, bid]);
  const {
    data: userData,
    isError,
    isSuccess,
  } = useGetSellersQuery(debouncedSearchTerm, {
    skip: debouncedSearchTerm === "",
  });
  useEffect(() => {
    if (assignSuccess) {
      trackEvent(Events.USER_MANUAL_ADDIGNED, manualUserData);
      toast.success("Sharer Assigned");
      // console.log("")
    } else if (assignError) {
      // toast.error(error.data.message);
      trackEvent(Events.USER_MANUAL_ADDIGNED_ERROR, error?.data?.message);
      toast.error(error?.data?.message);
    }
  }, [assignError, assignSuccess, addBidUser, CustomButton]);
  useEffect(() => {
    if (userstatus) {
      getAddedUsers(bid?._id ? bid?._id : bid?.id);
    } else {
    }
  }, [userstatus]);

  //when uselazy called
  useEffect(() => {
    if (suc) {
      setAddedUsers(addedUsersData);
      //  console.log("addedUsersData",addedUsers)
    } else if (iserr) {
      // console.log(err.data.message);
      toast.error("There is no assigned users yet");
    }
  }, [iserr, suc, load, addedUsersData, userstatus, addedUsers]);

  const navigate = useNavigate();
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const openPaymentModal = () => {
    setShowPaymentModal(true);
  };
  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };
  const toggleAddButton = () => {
    setIsAddButtonEnabled(!isAddButtonEnabled);
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1000);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchTerm]);

  const handleSearchInputChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    setIsUserAvailable(true);
  };

  useEffect(() => {
    if (issuc) {
      // console.log("paymentIntent, ephemeralKey, customer",data )
      // const intentKey = data.paymentIntent;
      // console.log("paymentIntent",intentKey )
      // dispatch(setIntentKey(intentKey));
    } else if (iserrr) {
      toast.error("Error fetching Payment data");
    }
  }, [issuc, iserrr]);

  useEffect(() => {
    if (isSuccess) {
      // Handle success, if needed
    } else if (isError) {
      addUserError();
      toast.error("Error fetching user data");
    }
  }, [isSuccess, isError]);

  const addUserError = () => {};

  const handleUserSelect = async (users) => {
    console.log("Selected users", users);

    if (userstatus) {
      // if (bid?.dataCategory?.questionnaire) {
      const valuesToSend = {
        bidId: bid._id ? bid._id : bid.id,
        sellerId: users.id,
        manualMatch: true,
        isAbsolute:
          bid?.configure?.steps?.configType == "Relative" ||
          bid?.configure?.flights?.configType == "Relative" ||
          bid?.configure?.elevation?.configType == "Relative" ||
          bid?.configure?.placesVisited?.configType == "Relative" ||
          bid?.configure?.noOfPlacesVisited?.configType == "Relative"
            ? false
            : true,
      };

      // console.log("SellerID>>>", users.id);
      await addBidUser(valuesToSend);
      await getAddedUsers(bid?._id);
      // } else {
      // toast.error("Please create a questionnaire first.");
      // }
    } else {
      if (!addedUsers.some((user) => user?.username === users?.username)) {
        const user = {
          username: users?.username,
          id: users.id,
        };
        setAddedUsers([...addedUsers, user]);
      }
    }
    setSearchTerm("");
    setSelectedUserId(users.id);
    setIsUserAvailable(false);
  };

  const handleCreatedButtonClick = async () => {
    const response = await paymentSheet(selectedUserIdd);

    if (!response) {
      console.error(`HTTP error! Status: ${response.status}`);
      return;
    }

    // const { paymentIntent, ephemeralKey, customer } = await response.json();

    // return { paymentIntent, ephemeralKey, customer };
  };

  const handleAutoMatchPress = useCallback(() => {
    const vts = {
      bidId: bid?._id,
      bid: bid,
    };
    autoMatch(vts);
  }, [bid]);
  return (
    <Container className="containerofassignuser">
      <div className="div1">
        {isAddButtonEnabled &&
        (bid?.dataItem?.specifiedValues?.length === 0 ||
          bid?.configure?.steps?.configType == "Relative" ||
          bid?.configure?.flights?.configType == "Relative" ||
          bid?.configure?.elevation?.configType == "Relative" ||
          bid?.configure?.noOfPlacesVisited?.configType == "Relative" ||
          bid?.configure?.placesVisited?.configType == "Relative" ||
          bid?.dataItem?.specifiedValues?.some(
            (i) => i.value === "Applications_Location_Google_Timeline"
          ) ||
          bid?.dataItem?.specifiedValues?.some(
            (i) => i.value === "Prediction_Predict"
          ) ||
          (!bid?.dataItem?.specifiedValues?.some((i) =>
            i.value?.includes("Applications")
          ) &&
            bid?.dataItem?.specifiedValues?.some((i) =>
              i.value?.includes("Therapies")
            ))) ? (
          <CustomButton
            className="Addauser"
            label={"Auto Match"}
            backgroundColor={"black"}
            // isLoading={isLoadingAuto}
            // style={{ paddingBottom: "15px", float: "right",backgroundColor:'black',color:'white' }}
            onClick={handleAutoMatchPress}
          />
        ) : (
          <h2>Assign a User</h2>
        )}
        {isAddButtonEnabled ? ( // Render button and text field conditionally
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <Button style={{width: "20vw"}} onClick={toggleAddButton}>Add a User</Button> */}
            <CustomButton
              className="Addauser"
              label={"Add a User"}
              style={{ paddingBottom: "15px", float: "right" }}
              onClick={toggleAddButton}
            />
          </div>
        ) : (
          <Form.Group className="div2">
            {/* <Form.Label className="username">User Name</Form.Label> */}
            <Row className="SearchItems">
              <Col sm={11}>
                <Form.Control
                  placeholder="Enter User Name"
                  className={`form-group`}
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                />
                {isUserAvailable && (
                  <ListGroup className="listsearches">
                    {Array.isArray(userData) && userData.length > 0 ? (
                      userData.map((user) => (
                        <ListGroup.Item
                          key={user.id}
                          onClick={() => handleUserSelect(user)}
                          className="list-group-item"
                          action
                        >
                          {user.username}
                        </ListGroup.Item>
                      ))
                    ) : (
                      <p>No users found</p>
                    )}
                  </ListGroup>
                )}
              </Col>
              <Col sm={1}>
                <div className="deselect-icon" onClick={toggleAddButton}>
                  <i className="fal fa-times"></i>
                </div>
              </Col>
            </Row>
          </Form.Group>
        )}
      </div>

      <div className="addedusers">
        <Row className="mb-2">
          <Col>
            <h3>Added User Names</h3>
          </Col>
        </Row>
        <Row
          className="row"
          style={{
            textAlign: "left",
            margin: "auto",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col style={{ marginBottom: "80px" }}>
            {load ||
            isLoadingAuto ||
            paymentLoading ||
            isLoadingUpdateSellers ? (
              <Loader style={{ padding: "50px" }} />
            ) : (
              <ListGroup>
                {addedUsers.length > 0 && addedUsers ? (
                  addedUsers.map((user, index) => {
                    return (
                      <ListGroup.Item
                        style={{
                          border: "none",
                          borderBottom: "2px solid rgb(145, 143, 143)",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                        key={index}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          {userstatus
                            ? `${user?.sellerRef?.username} ${
                                user?.sellerRef?.clinical?.institution
                                  ? `(${user.sellerRef.clinical.institution})`
                                  : ""
                              }`
                            : user?.username}
                        </span>

                        <div
                          className="d-flex gap-2"
                          style={{
                            minWidth: "30%",
                            justifyContent: "flex-end",
                          }}
                        >
                          <CustomButton
                            style={{ paddingBottom: "15px", float: "right" }}
                            label={
                              user?.status === "Accepted" ||
                              user?.status === "Payment_Sent"
                                ? "Accepted"
                                : user?.status === "Rejected"
                                ? "Rejected"
                                : user?.status === "Requested"
                                ? "Requested"
                                : user?.status === "Pending"
                                ? "Pay"
                                : user?.status === "Modified"
                                ? "Modified"
                                : user?.status === "Added"
                                ? "Customize"
                                : user?.status === "Created"
                                ? "Created"
                                : ""
                            }
                            color={
                              user?.status === "Accepted" ||
                              user?.status === "Payment_Sent"
                                ? "green"
                                : user?.status === "Rejected"
                                ? "red"
                                : user?.status === "Requested"
                                ? "grey"
                                : user?.status === "Pending"
                                ? "grey"
                                : user?.status === "Modified"
                                ? "grey"
                                : user?.status === "Added"
                                ? "grey"
                                : "black"
                            }
                            textcolor={"white"}
                            onClick={() => {
                              if (
                                user?.status === "Accepted" ||
                                user?.status === "Payment_Sent"
                              ) {
                                navigate(RouteNames.CSV.route, {
                                  state: { user },
                                });
                              } else if (user?.status === "Rejected") {
                                alert("Rejected");
                              } else if (user?.status === "Added") {
                                setSelectedUserId(user);
                                openModal();
                              } else if (user?.status === "Created") {
                                setSelectedUserId(user);
                                handleCreatedButtonClick();
                              } else if (user?.status === "Pending") {
                                setSelectedUserId(user);
                                fetchPaymentSheetParams(user);
                              }
                            }}
                            width={"100%"}
                            type={
                              user?.status === "Created" ? "submit" : undefined
                            }
                          />
                          {(user?.status === "Accepted" ||
                            user?.status === "Payment_Sent") && (
                            <>
                              <button
                                className="icon-buttons"
                                onClick={() => {
                                  navigate(RouteNames.CSV.route, {
                                    state: { user },
                                  });
                                }}
                              >
                                <IoMdDownload size={23} />
                              </button>

                              {(user?.status === "Accepted" ||
                                user?.status === "Payment_Sent") &&
                                user?.dataItem?.specifiedValues?.some(
                                  (i) => i?.value === "Prediction_Predict"
                                ) && (
                                  <button
                                    className="icon-buttons"
                                    onClick={() => {
                                      navigate("/user-prediction", {
                                        state: { user: user.sellerRef },
                                      });
                                    }}
                                  >
                                    <MdAutoGraph size={23} />
                                  </button>
                                )}
                            </>
                          )}
                        </div>
                      </ListGroup.Item>
                    );
                  })
                ) : (
                  <>you haven't added any seller for this bid</>
                )}
              </ListGroup>
            )}
          </Col>
        </Row>
      </div>
      {/* <div className="addedusers"> */}
      <Row>
        <Col className="d-flex justify-content-center">
          <CustomButton
            style={{ paddingBottom: "15px", float: "right" }}
            label={"Request Data Update"}
            color={"black"}
            textcolor={"white"}
            // onClick={openUserModal}
            onClick={async () => {
              const userIds = addedUsers.map((user, index) =>
                user?.status === "Accepted" || user?.status === "Payment_Sent"
                  ? user?.sellerRef?.id
                  : null
              );

              const vts = {
                sellers: userIds,
              };
              console.log("UpdateSellers", vts);
              await UpdateSellers(vts);
              setShowCustomCard(false);
              // console.log(userIds)
            }}
            width={"fit-content"}
          />
        </Col>
      </Row>
      {/* </div> */}
      {/* {showPaymentForm && (
  <form action={`"https://api.d.axontechs.com/v1/payment/payment-sheet/${selectedUserId?.id}`} method="POST">
  
    
  </form>
)} */}
      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          {/* {toast.success("HII")} */}
          <StripePayment
            show={showPaymentModal}
            handleClose={closePaymentModal}
            selectedBid={selectedUserId}
            paymentDoc={paymentDoc}
            refetch={getAddedUsers}
            // selectedUserId={selectedUserId}
            // refetch={getAddedUsers}
          />
        </Elements>
      )}
      <CustomizeModal
        show={showModal}
        handleClose={closeModal}
        userId={userId}
        bid={bid}
        selectedUserId={selectedUserId}
        refetch={getAddedUsers}
      />
      <Modal
        isOpen={showCustomCard}
        onRequestClose={() => setShowCustomCard(false)}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "relative",
            width: "500px",
            height: "500px",
            margin: "0",
            padding: "32px",
            borderRadius: "12px",
            background: "#ffffff",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            border: "none",
          },
        }}
      >
        <h2
          style={{
            marginTop: 0,
            marginBottom: "24px",
            textAlign: "center",
          }}
        >
          User Details
        </h2>
        <button
          onClick={() => setShowCustomCard(false)}
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
            background: "none",
            border: "none",
            cursor: "pointer",
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div style={{ maxHeight: "calc(100% - 100px)", overflowY: "auto" }}>
          {addedUsers?.length > 0 ? (
            addedUsers.map((user, index) =>
              user?.status === "Accepted" || user?.status === "Payment_Sent" ? (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "16px",
                    padding: "12px",
                    borderRadius: "8px",
                    background: "#f0f0f0",
                  }}
                >
                  <h5 style={{ margin: 0 }}>{user?.sellerRef?.username}</h5>
                  <button
                    onClick={() => {
                      navigate("/user-prediction", {
                        state: { user: user.sellerRef },
                      });
                    }}
                    style={{
                      padding: "8px 16px",
                      background: "#5b99a6",
                      color: "#ffffff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Predict
                  </button>
                </div>
              ) : null
            )
          ) : (
            <p style={{ textAlign: "center", color: "#666" }}>
              No Users Assigned
            </p>
          )}
          {Array.isArray(addedUsers) &&
            addedUsers.some(
              (aud) =>
                (aud?.status === "Accepted" ||
                  aud?.status === "Payment_Sent") &&
                aud?.dataItem?.specifiedValues?.some(
                  (i) => i?.value === "Prediction_Predict"
                )
            ) && (
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  position: "absolute",
                  bottom: "25px",
                  left: "0px",
                }}
              >
                <button
                  onClick={async () => {
                    const userIds = addedUsers.map((aud, index) =>
                      (aud?.status === "Accepted" ||
                        aud?.status === "Payment_Sent") &&
                      aud?.dataItem?.specifiedValues?.some(
                        (i) => i?.value === "Prediction_Predict"
                      )
                        ? aud?.sellerRef?.id
                        : null
                    );

                    const vts = {
                      sellers: userIds,
                    };
                    console.log("UpdateSellers", vts);
                    await UpdateSellers(vts);
                    setShowCustomCard(false);
                    // console.log(userIds)
                  }}
                  style={{
                    width: "80%",
                    background: "#5b99a6",
                    color: "#ffffff",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Notify everyone to update their data
                </button>
              </div>
            )}
        </div>
      </Modal>
    </Container>
  );
}

export default React.memo(AssignUser);
